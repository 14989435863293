import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  CssBaseline,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import {
  AccountCircle,
  ExitToApp,
  Business,
  Dashboard,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import theme from './theme';

const drawerWidth = 250;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem('token');
    logout();
    navigate('/signin');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Job Listings', icon: <Dashboard />, path: '/' },
    { text: 'Account', icon: <AccountCircle />, path: '/account' },
    { text: 'Organizations', icon: <Business />, path: '/organizations' },
  ];

  const drawer = (
    <>
      <Box sx={{ p: 3, pb: 0 }}>
        <img
          src="/logo.png"
          alt="Retell AI Logo"
          style={{ width: '100px', height: 'auto' }}
        />
      </Box>
      <List sx={{ px: 2, py: 1, flexGrow: 1 }}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => item.path && navigate(item.path)}
            selected={item.path === location.pathname}
            sx={{
              my: 0.5,
              borderRadius: '4px',
              color: item.path === location.pathname ? theme.palette.primary.main : 'inherit',
            }}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ px: 2, pb: 2 }}>
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            my: 0.5,
            borderRadius: '4px',
            color: theme.palette.error.main,
          }}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </Box>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '100vh', bgcolor: 'background.default' }}>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, position: 'absolute', top: 8, left: 8, zIndex: 1100 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              border: 'none',
              bgcolor: 'background.default',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3, 
            overflowY: 'auto', 
            bgcolor: 'background.default',
            marginLeft: isMobile ? 0 : `${drawerWidth}px`,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;