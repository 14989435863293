import React from 'react';
import { Typography, TextField, Button, Grid, Box, CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function UserInfoStep({ userInfo, onInputChange, onSubmit, onBack, isSubmitting }) {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };

    const handlePhoneChange = (value) => {
        // Format the phone number to include the "+" and remove any non-digit characters
        const formattedPhoneNumber = value.replace(/\D/g, '');
        onInputChange({ target: { name: 'phoneNumber', value: formattedPhoneNumber } });
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            minHeight: '100vh', // Full viewport height
            pt: 2, // Add top padding
        }}>
            <Box 
                component="form" 
                onSubmit={handleSubmit}
                sx={{
                    width: '100%',
                    maxWidth: 600, // Limit max width
                    border: `2px solid #4a8f99`,
                    borderRadius: '8px',
                    padding: '24px',
                }}
            >
                {userInfo.error && (
                    <Typography color="error" sx={{ mb: 2 }}>
                        {userInfo.error}
                    </Typography>
                )}
                <Typography variant="h5" align="center" sx={{ mb: 4, color: '#4a8f99' }}>
                    Let's Get Some Information About You
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={userInfo.firstName}
                            onChange={onInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={userInfo.lastName}
                            onChange={onInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneInput
                            country={'us'}
                            value={userInfo.phoneNumber.replace('+', '')}
                            onChange={handlePhoneChange}
                            inputProps={{
                                required: true,
                                name: 'phoneNumber'
                            }}
                            containerStyle={{ marginTop: '8px', marginBottom: '8px' }}
                            inputStyle={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={userInfo.email}
                            onChange={onInputChange}
                            required
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button onClick={onBack} disabled={isSubmitting}>
                        Back
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                    >
                        {isSubmitting ? 'Submitting...' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}