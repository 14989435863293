import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PostHogProvider } from 'posthog-js/react'
import { initSentry } from './sentry';
import { getPostHogConfig } from './posthog';

// Initialize Sentry
initSentry();

// Get PostHog configuration
const { apiKey, options } = getPostHogConfig();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={apiKey}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);