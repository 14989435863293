import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper
} from '@mui/material';

const ResumeInfoReview = ({ onSubmit }) => {
  const [info, setInfo] = useState({
    experience: '',
    education: '',
    preferredLocation: '',
    workAuthorization: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInfo(prevInfo => ({
      ...prevInfo,
      [name]: name === 'experience' ? (value === '' ? '' : parseInt(value, 10)) : value
    }));
  };

  const handleSubmit = () => {
    console.log('Submitting info:', info);
    onSubmit(info);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>Resume Information</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Please fill in the following information about your resume.
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Experience"
            name="experience"
            type="number"
            value={info.experience}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Education</InputLabel>
            <Select
              name="education"
              value={info.education}
              onChange={handleChange}
              label="Education"
            >
              <MenuItem value="No Formal">No Formal</MenuItem>
              <MenuItem value="Associates">Associates</MenuItem>
              <MenuItem value="Bachelors">Bachelors</MenuItem>
              <MenuItem value="Masters">Masters</MenuItem>
              <MenuItem value="PhD">PhD</MenuItem>
              <MenuItem value="Post-Doc">Post-Doc</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Preferred Location</InputLabel>
            <Select
              name="preferredLocation"
              value={info.preferredLocation}
              onChange={handleChange}
              label="Preferred Location"
            >
              <MenuItem value="In Person">In Person</MenuItem>
              <MenuItem value="Remote">Remote</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Work Authorization</InputLabel>
            <Select
              name="workAuthorization"
              value={info.workAuthorization}
              onChange={handleChange}
              label="Work Authorization"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 3 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSubmit}
          sx={{
            bgcolor: '#4a8f99',
            '&:hover': { bgcolor: '#3b7b84' }
          }}
        >
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default ResumeInfoReview;