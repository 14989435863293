const DEFAULT_API_BASE_URL = 'http://localhost:8000';
const DEFAULT_WEBSITE_URL = 'http://localhost:3000';
const API_PREFIX = '/api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL;
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || DEFAULT_WEBSITE_URL;

const API_URL = `${API_BASE_URL}${API_PREFIX}`;

const config = { API_URL, WEBSITE_URL };
export default config;