import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import config from './config';

const JobDetailsPage = () => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [openCloseJobDialog, setOpenCloseJobDialog] = useState(false);
  const [closeDate, setCloseDate] = useState(dayjs());

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`${config.API_URL}/jobs/${jobId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch job details');
        }

        const data = await response.json();
        setJob(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleCandidateClick = (candidateId) => {
    navigate(`/candidates/${candidateId}`);
  };

  const handleCloseJobClick = () => {
    setOpenCloseJobDialog(true);
  };

  const handleCloseJobDialogClose = () => {
    setOpenCloseJobDialog(false);
  };

  const handleCloseJobSubmit = async () => {
    try {
      const response = await fetch(`${config.API_URL}/jobs/${jobId}/close-date`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ close_date: closeDate.toISOString() }),
      });

      if (!response.ok) {
        throw new Error('Failed to update job close date');
      }

      setOpenCloseJobDialog(false);
    } catch (err) {
      console.error('Error closing job:', err);
      // Handle error (e.g., show an error message to the user)
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  if (!job) {
    return (
      <Typography variant="h6">
        Job not found
      </Typography>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 2, color: '#4a8f99' }}>
        {job.title}
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Organization: {job.organization_name}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        {job.description}
      </Typography>

      <Typography variant="h5" sx={{ mb: 2, mt: 4, color: '#4a8f99' }}>
        Applicants and Candidates
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Application Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Stage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {job.applicants && job.applicants.length > 0 ? (
              job.applicants.map((applicant) => (
                <TableRow
                  key={applicant.id}
                  onClick={() => handleCandidateClick(applicant.id)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' }
                  }}
                >
                  <TableCell>{applicant.name}</TableCell>
                  <TableCell>{applicant.email}</TableCell>
                  <TableCell>
                    {applicant.createdAt
                      ? new Date(applicant.createdAt).toLocaleString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{applicant.status}</TableCell>
                  <TableCell>{applicant.stage || 'N/A'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">No applicants yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseJobClick}
          sx={{ mr: 2 }}
        >
          Close Job
        </Button>
        {/* Existing delete button */}
      </Box>

      <Dialog open={openCloseJobDialog} onClose={handleCloseJobDialogClose}>
        <DialogTitle>Set Job Close Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Close Date"
              value={closeDate}
              onChange={(newValue) => setCloseDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJobDialogClose}>Cancel</Button>
          <Button onClick={handleCloseJobSubmit} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobDetailsPage;