import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  CircularProgress,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import config from './config';
import CandidateDetailsComponent from './CandidateDetailsComponent';

const createApi = () => axios.create({
  baseURL: config.API_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  },
});

const JobListingsPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const [filters, setFilters] = useState({});

  const [candidates, setCandidates] = useState([]);
  const [, setOriginalCandidates] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [currentInboxIndex, setCurrentInboxIndex] = useState(0);
  const [openCloseJobDialog, setOpenCloseJobDialog] = useState(false);
  const [jobCloseDate, setJobCloseDate] = useState(null);

  const inboxCandidates = useMemo(() =>
    candidates.filter(c => c.stage === 'Inbox'),
    [candidates]
  );

  const apiRef = useRef(createApi());

  const fetchJobs = useCallback(async () => {
    try {
      const response = await apiRef.current.get('/jobs/');
      setJobs(response.data);
      if (response.data.length > 0) {
        await fetchJobDetails(response.data[0].id);
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to fetch job postings');
      setJobs([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const fetchJobDetails = async (jobId) => {
    try {
      const [jobResponse, candidatesResponse, closeDateResponse] = await Promise.all([
        apiRef.current.get(`/jobs/${jobId}`),
        apiRef.current.get(`/jobs/${jobId}/candidates`),
        apiRef.current.get(`/jobs/${jobId}/close-date`)
      ]);
      
      const jobData = jobResponse.data;
      setSelectedJob(jobData);
      setCandidates(candidatesResponse.data);
      setOriginalCandidates(candidatesResponse.data);

      if (closeDateResponse.data && closeDateResponse.data.close_date) {
        setJobCloseDate(dayjs(closeDateResponse.data.close_date));
      } else {
        setJobCloseDate(null);
      }

      setCurrentInboxIndex(0);

      // Initialize filters based on interview_questions
      const initialFilters = {};
      jobData.interview_questions.forEach((question) => {
        initialFilters[question.question] = question.question_type === 'number' 
          ? { operator: 'gt', value: '' } 
          : '';
      });
      setFilters(initialFilters);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to fetch job details');
    }
  };

  const handleJobSelect = (job) => {
    fetchJobDetails(job.id);
  };

  const handleAddJob = () => {
    navigate('/add-job');
  };

  const handleEditJob = (job) => {
    navigate('/add-job', { state: { editJob: job } });
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteConfirmation.toLowerCase() === 'yes') {
      try {
        await apiRef.current.delete(`/jobs/${selectedJob.id}`);
        await fetchJobs();
        setSelectedJob(null);
      } catch (err) {
        setError(err.response?.data?.detail || 'Failed to delete job');
      }
    }
    setDeleteDialogOpen(false);
    setDeleteConfirmation('');
  };

  const truncateDescription = (description, maxLength = 100) => {
    if (description.length <= maxLength) return description;
    return `${description.substring(0, maxLength)}...`;
  };

  const handleFilterChange = (questionKey, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [questionKey]: value,
    }));
  };

  const clearFilters = () => {
    const clearedFilters = {};
    Object.keys(filters).forEach(key => {
      clearedFilters[key] = selectedJob.interview_questions.find(q => q.question === key).question_type === 'number'
        ? { operator: 'gt', value: '' }
        : '';
    });
    setFilters(clearedFilters);
    if (selectedJob) {
      fetchJobDetails(selectedJob.id);
    }
  };

  const applyFilters = async () => {
    try {
      const response = await apiRef.current.get(`/jobs/${selectedJob.id}/candidates`, {
        params: { filters: JSON.stringify(filters) }
      });
      setCandidates(response.data);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to apply filters');
    }
  };

  const handleCopyApplyUrl = () => {
    const applyUrl = `${config.WEBSITE_URL}/apply/${selectedJob.external_id}`;
    navigator.clipboard.writeText(applyUrl).then(() => {
      alert('Apply URL copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleNextInboxCandidate = useCallback(() => {
    if (inboxCandidates.length === 0) {
      setSnackbarMessage('No inbox candidates available');
      setSnackbarOpen(true);
      return;
    }

    const nextIndex = (currentInboxIndex + 1) % inboxCandidates.length;
    setCurrentInboxIndex(nextIndex);
    setSelectedCandidateId(inboxCandidates[nextIndex].id);
  }, [inboxCandidates, currentInboxIndex, setSnackbarMessage, setSnackbarOpen]);

  const handleStageChange = useCallback(async (candidateId, newStage) => {
    try {
      const id = typeof candidateId === 'object' && candidateId !== null ? candidateId.id : candidateId;

      await axios.patch(`${config.API_URL}/jobs/candidates/${id}/stage`,
        { stage: newStage },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );

      const updateCandidates = (prevCandidates) =>
        prevCandidates.map(candidate =>
          candidate.id === id ? { ...candidate, stage: newStage } : candidate
        );

      setCandidates(updateCandidates);
      setOriginalCandidates(updateCandidates);

      if (newStage !== 'Inbox' && id === selectedCandidateId) {
        handleNextInboxCandidate();
      }
    } catch (error) {
      console.error('Failed to update stage:', error);
      setSnackbarMessage('Failed to update candidate stage');
      setSnackbarOpen(true);
    }
  }, [selectedCandidateId, handleNextInboxCandidate, setSnackbarMessage, setSnackbarOpen]);

  const handleCandidateClick = useCallback((event, candidateId) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('Candidate clicked:', candidateId);
    setSelectedCandidateId(candidateId);

    const inboxCandidates = candidates.filter(c => c.stage === 'Inbox');
    const index = inboxCandidates.findIndex(c => c.id === candidateId);
    if (index !== -1) {
      setCurrentInboxIndex(index);
    }
  }, [candidates]);

  const handleCloseCandidateDetails = useCallback(() => {
    console.log('Closing candidate details');
    setSelectedCandidateId(null);
    setCurrentInboxIndex(0);
  }, []);

  const handleCloseJobClick = () => {
    setOpenCloseJobDialog(true);
  };

  const handleCloseJobDialogClose = () => {
    setOpenCloseJobDialog(false);
  };

  const handleCloseJobSubmit = async () => {
    try {
      const response = await apiRef.current.put(`/jobs/${selectedJob.id}/close-date`, {
        close_date: jobCloseDate.toISOString()
      });

      if (response.status === 200) {
        setOpenCloseJobDialog(false);
        setSnackbarMessage('Job close date updated successfully');
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error('Error closing job:', err);
      setSnackbarMessage('Failed to update job close date');
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', gap: 3 }}>
      {selectedCandidateId ? (
        <CandidateDetailsComponent
          candidateId={selectedCandidateId}
          onClose={handleCloseCandidateDetails}
          jobId={selectedJob?.id}
          currentInboxIndex={currentInboxIndex}
          totalInboxCandidates={inboxCandidates.length}
          onNextCandidate={handleNextInboxCandidate}
          onStageChange={handleStageChange}
        />
      ) : (
        <>
          <Paper
            elevation={1}
            sx={{
              width: 250,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddJob}
                sx={{
                  py: 1,
                  bgcolor: theme.palette.primary.main,
                  '&:hover': { bgcolor: theme.palette.primary.dark },
                  borderRadius: theme.shape.borderRadius,
                }}
              >
                Add Job
              </Button>
            </Box>
            <List sx={{ flexGrow: 1, overflowY: 'auto', py: 1, px: 2 }}>
              {jobs.map((job) => (
                <ListItem
                  button
                  key={job.id}
                  onClick={() => handleJobSelect(job)}
                  selected={selectedJob && selectedJob.id === job.id}
                  sx={{
                    borderRadius: '4px',
                    my: 0.5,
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(74, 143, 153, 0.15)',
                      '&:hover': {
                        backgroundColor: 'rgba(74, 143, 153, 0.25)',
                      },
                    },
                  }}
                >
                  <ListItemText primary={job.title} />
                </ListItem>
              ))}
            </List>
          </Paper>

          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
            {selectedJob ? (
              <>
                <Paper elevation={1} sx={{ p: 3, borderRadius: 2, position: 'relative' }}>
                  <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <Tooltip title="Set Close Date">
                      <IconButton
                        onClick={handleCloseJobClick}
                        sx={{ mr: 1 }}
                      >
                        <AccessTimeIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Job">
                      <IconButton
                        onClick={() => handleEditJob(selectedJob)}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Job">
                      <IconButton
                        onClick={handleDeleteClick}
                        sx={{
                          '&:hover': {
                            color: 'error.main',
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="h4" sx={{ mb: 2, color: theme.palette.primary.main, pr: 4 }}>
                    {selectedJob.title}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Company:
                      </Typography>
                      <Typography variant="body1">
                        {selectedJob.company_name || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Organization:
                      </Typography>
                      <Typography variant="body1">
                        {selectedJob.organization_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                    Job Description:
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {truncateDescription(selectedJob.description)}
                  </Typography>
                </Paper>

                <Paper elevation={1} sx={{ p: 3, borderRadius: 2, flexGrow: 1, overflowY: 'auto' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                      Applicants
                    </Typography>
                    <Tooltip title="Copy Apply Job URL">
                      <IconButton onClick={handleCopyApplyUrl}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Application Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Stage</TableCell>
                          <TableCell>Cohort</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {candidates.length > 0 ? (
                          candidates.map((candidate) => (
                            <TableRow
                              key={candidate.id}
                              sx={{
                                cursor: 'pointer',
                                '&:hover': { backgroundColor: theme.palette.action.hover }
                              }}
                              onClick={(event) => handleCandidateClick(event, candidate.id)}
                            >
                              <TableCell>{candidate.first_name} {candidate.last_name}</TableCell>
                              <TableCell>{candidate.email}</TableCell>
                              <TableCell>
                                {candidate.created_at
                                  ? new Date(candidate.created_at).toLocaleString()
                                  : 'N/A'}
                              </TableCell>
                              <TableCell>{candidate.status}</TableCell>
                              <TableCell>
                                <FormControl variant="outlined" size="small">
                                  <Select
                                    value={candidate.stage || ''}
                                    onChange={(e) => handleStageChange(candidate.id, e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                    displayEmpty
                                    renderValue={(value) => value || 'Select Stage'}
                                    IconComponent={ArrowDropDownIcon}
                                    sx={{
                                      '.MuiSelect-select': {
                                        padding: '6px 12px',
                                        borderRadius: '16px',
                                        color: '#4a8f99',
                                        border: '1px solid #4a8f99',
                                      },
                                      '.MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                    }}
                                  >
                                    <MenuItem value="Pending">Pending</MenuItem>
                                    <MenuItem value="Inbox">Inbox</MenuItem>
                                    <MenuItem value="Interview">Interview</MenuItem>
                                    <MenuItem value="Offer">Offer</MenuItem>
                                    <MenuItem value="Archive">Archive</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                {candidate.ranking === 2 ? 'A' :
                                  candidate.ranking === 1 ? 'B' :
                                    candidate.ranking === 0 ? 'C' : ''}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center">No applicants yet</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </>
            ) : (
              <Typography variant="h6">Select a job to view details</Typography>
            )}
          </Box>

          <Paper elevation={1} sx={{ width: 300, p: 3, borderRadius: 2, overflowY: 'auto' }}>
            <Typography variant="h5" sx={{ mb: 2, color: theme.palette.primary.main }}>
              Filters
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {selectedJob && selectedJob.interview_questions.map((question) => (
                <Box key={question.question}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>{question.question}</Typography>
                  {question.question_type === 'shortAnswer' && (
                    <TextField
                      fullWidth
                      type="number"
                      label="Minimum Score"
                      value={filters[question.question]}
                      onChange={(e) => handleFilterChange(question.question, e.target.value)}
                      InputProps={{ inputProps: { min: 1, max: 5 } }}
                    />
                  )}
                  {question.question_type === 'boolean' && (
                    <FormControl fullWidth>
                      <Select
                        value={filters[question.question]}
                        onChange={(e) => handleFilterChange(question.question, e.target.value)}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {question.question_type === 'number' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <FormControl sx={{ width: '30%' }}>
                        <Select
                          value={filters[question.question].operator}
                          onChange={(e) => handleFilterChange(question.question, { ...filters[question.question], operator: e.target.value })}
                        >
                          <MenuItem value="gt">&gt;</MenuItem>
                          <MenuItem value="lt">&lt;</MenuItem>
                          <MenuItem value="eq">=</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        type="number"
                        value={filters[question.question].value}
                        onChange={(e) => handleFilterChange(question.question, { ...filters[question.question], value: e.target.value })}
                        sx={{ width: '70%' }}
                      />
                    </Box>
                  )}
                </Box>
              ))}

              <Button variant="outlined" onClick={clearFilters} sx={{ mb: 1 }}>
                Clear Filters
              </Button>
              <Button variant="contained" onClick={applyFilters}>
                Apply Filters
              </Button>
            </Box>
          </Paper>
        </>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this job? This action cannot be undone. Please type "yes" to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="confirmation"
            label="Type 'yes' to confirm"
            type="text"
            fullWidth
            variant="standard"
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openCloseJobDialog} onClose={handleCloseJobDialogClose}>
        <DialogTitle>Set Job Close Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Close Date"
              value={jobCloseDate}
              onChange={(newValue) => setJobCloseDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    mt: 2,
                    '& .MuiInputLabel-root': {
                      transform: 'translate(14px, -9px) scale(0.75)',
                      background: '#fff',
                      padding: '0 8px',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJobDialogClose}>Cancel</Button>
          <Button onClick={handleCloseJobSubmit} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobListingsPage;