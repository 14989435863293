import React from 'react';
import { Box, Typography, Paper, Container } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ApplicationCompletePage = () => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: '#4caf50', mb: 2 }} />
          <Typography variant="h4" gutterBottom>
            Application Complete
          </Typography>
          <Typography variant="body1">
            You have completed your application. Thank you!
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            You may now close this page.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default ApplicationCompletePage;