import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography, Paper, CircularProgress, Box, List, ListItem, ListItemText,
  Button, Grid, Accordion, AccordionSummary, AccordionDetails, Card, CardContent,
  Slider, Snackbar, Alert, Select, MenuItem, FormControl, OutlinedInput, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { Email, GetApp, LinkedIn, ExpandMore, PlayArrow, ArrowBack, Pause, Speed, NavigateNext, QuestionAnswer, CheckCircle, Cancel } from '@mui/icons-material';
import TranscriptDisplay from './TranscriptDisplay';
import config from './config';
import axios from 'axios';

const CandidateDetailsComponent = ({ candidateId, onClose, jobId, currentInboxIndex, totalInboxCandidates, onNextCandidate, onStageChange }) => {
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playbackError, setPlaybackError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const audioRef = useRef(null);
  const [stage, setStage] = useState('');
  const [notes, setNotes] = useState('');
  const [applicationId, setApplicationId] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [openNoResumeDialog, setOpenNoResumeDialog] = useState(false);

  useEffect(() => {
    console.log('CandidateDetailsComponent mounted with ID:', candidateId);
    const fetchCandidateDetails = async () => {
      try {
        const id = typeof candidateId === 'object' && candidateId !== null ? candidateId.id : candidateId;
        console.log('Fetching details for candidate:', id);
        const response = await axios.get(`${config.API_URL}/jobs/candidates/${id}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Fetched candidate details:', response.data);
        setCandidate(response.data);
        setNotes(response.data?.notes || '')
        setApplicationId(response.data?.id)
        setStage(response.data?.stage || 'Pending');
      } catch (err) {
        console.error('Error fetching candidate details:', err.response ? err.response.data : err.message);
        setError(err.response ? err.response.data.detail : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCandidateDetails();
  }, [candidateId]);

  useEffect(() => {
    console.log('Current stage:', stage);
  }, [stage]);

  const handleAudioError = () => {
    setSnackbarMessage('Audio file not found');
    setPlaybackError(true);
    setSnackbarOpen(true);
  };
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const saveNotes = useCallback(async () => {
    if (!applicationId) return;

    try {
      await axios.patch(`${config.API_URL}/users/update_interview_data/${applicationId}/`, { notes });
    } catch (error) {
      console.error("Error updating notes:", error);
    }
  }, [applicationId, notes]);
  const handlePlayPause = async () => {
    try {
      if (audioRef.current.paused) {
        await audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    } catch (err) {
      console.error('Error during audio playback:', err);
      setSnackbarMessage('Unable to play audio. The media resource might be unavailable.');
      setPlaybackError(true);
      setSnackbarOpen(true);
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleSliderChange = (event, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };
  const handleSendEmail = () => {
    if (candidate.email) {
      window.location.href = `mailto:${candidate.email}`;
    }
  };

  const handleDownloadResume = async () => {
    try {
      const response = await fetch(`${config.API_URL}/jobs/resume/${candidate.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 404) {
        setOpenNoResumeDialog(true);
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the filename from the Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
      const filename = filenameMatch ? filenameMatch[1] : 'resume.pdf';

      // Create a blob from the response
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading resume:', error);
      setSnackbarMessage('Error downloading resume');
      setSnackbarOpen(true);
    }
  };

  const handleCloseNoResumeDialog = () => {
    setOpenNoResumeDialog(false);
  };

  const handlePreviewLinkedIn = () => {
    if (candidate.linkedin_link) {
      window.location.href = candidate.linkedin_link;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handlePlaybackRateChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setPlaybackRate(newValue);
    if (audioRef.current) {
      audioRef.current.playbackRate = newValue;
    }
  };

  const handleDownloadAudio = () => {
    if (candidate.audio_file_url) {
      window.open(candidate.audio_file_url, '_blank');
    }
  };

  const renderInterviewResponse = (responseData) => {
    const { question_type, response } = responseData;
    
    const commonStyles = {
      display: 'flex',
      alignItems: 'flex-start',
      mb: 1,
    };

    const iconStyle = {
      mr: 1,
      mt: 0.5,
    };

    switch (question_type) {
      case 'shortAnswer':
        return (
          <Box>
            <Box sx={commonStyles}>
              <QuestionAnswer sx={iconStyle} color="primary" />
              <Typography variant="body1"><strong>Answer:</strong> {response.eval_reasoning}</Typography>
            </Box>
            <Box sx={commonStyles}>
              <Speed sx={iconStyle} color="secondary" />
              <Typography variant="body1"><strong>Score:</strong> {response.eval_score}/5</Typography>
            </Box>
          </Box>
        );
      case 'boolean':
        return (
          <Box>
            <Box sx={commonStyles}>
              {response.answer ? (
                <CheckCircle sx={iconStyle} color="success" />
              ) : (
                <Cancel sx={iconStyle} color="error" />
              )}
              <Typography variant="body1"><strong>Answer:</strong> {response.answer ? 'Yes' : 'No'}</Typography>
            </Box>
            <Box sx={commonStyles}>
              <QuestionAnswer sx={iconStyle} color="primary" />
              <Typography variant="body1"><strong>Reasoning:</strong> {response.reasoning}</Typography>
            </Box>
          </Box>
        );
      case 'number':
        return (
          <Box>
            <Box sx={commonStyles}>
              <Speed sx={iconStyle} color="primary" />
              <Typography variant="body1"><strong>Answer:</strong> {response.answer}</Typography>
            </Box>
            <Box sx={commonStyles}>
              <QuestionAnswer sx={iconStyle} color="secondary" />
              <Typography variant="body1"><strong>Reasoning:</strong> {response.reasoning}</Typography>
            </Box>
          </Box>
        );
      default:
        return <Typography>Unknown question type</Typography>;
    }
  };

  const handleNextCandidate = () => {
    if (currentInboxIndex + 1 < totalInboxCandidates) {
      onNextCandidate();
    } else {
      // No more candidates in the inbox, go back to job listing
      onClose();
    }
  };

  const handleStageChange = async (candidateId, newStage) => {
    try {
      // Call the parent component's onStageChange function
      await onStageChange(candidateId, newStage);
      
      // Update the local state
      setCandidate(prevCandidate => ({
        ...prevCandidate,
        stage: newStage
      }));
      
      // Optionally, you can show a success message
      setSnackbarMessage('Stage updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating stage:', error);
      // Optionally, you can show an error message
      setSnackbarMessage('Failed to update stage');
      setSnackbarOpen(true);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography variant="h6" color="error">{error}</Typography>;
  if (!candidate) return <Typography>No candidate data available</Typography>;

  console.log('Rendering CandidateDetailsComponent with candidate:', candidate);

  return (
    <Box sx={{ p: 3, position: 'relative', minHeight: '100vh' }}>
      <Dialog open={openNoResumeDialog} onClose={handleCloseNoResumeDialog}>
        <DialogTitle>No Resume Found</DialogTitle>
        <DialogContent>
          <Typography>
            No resume was found for this candidate. They may not have uploaded one during the application process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoResumeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4">{candidate.first_name} {candidate.last_name}</Typography>
        <Box>
          <Button
            startIcon={<ArrowBack />}
            variant="outlined"
            onClick={onClose}
            sx={{ mr: 2 }}
          >
            Back to Jobs
          </Button>
          {currentInboxIndex + 1 < totalInboxCandidates ? (
            <Button
              endIcon={<NavigateNext />}
              variant="contained"
              onClick={handleNextCandidate}
              sx={{ mr: 2 }}
            >
              Next Inbox Candidate ({currentInboxIndex + 1}/{totalInboxCandidates})
            </Button>
          ) : (
            <Button
              startIcon={<ArrowBack />}
              variant="contained"
              onClick={onClose}
              sx={{ mr: 2 }}
            >
              Back to Job Listing
            </Button>
          )}
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <Select
              value={candidate?.stage || ''}
              onChange={(e) => handleStageChange(candidate.id, e.target.value)}
              label="Stage"
              input={<OutlinedInput label="Stage" />}
              sx={{
                '.MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '6px 12px',
                  borderRadius: '16px',
                  color: '#4a8f99',
                  border: '1px solid #4a8f99',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Inbox">Inbox</MenuItem>
              <MenuItem value="Interview">Interview</MenuItem>
              <MenuItem value="Offer">Offer</MenuItem>
              <MenuItem value="Archive">Archive</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Button startIcon={<Email />} variant="outlined" sx={{ mr: 1 }} onClick={handleSendEmail}>
          Send Email
        </Button>
        <Button startIcon={<GetApp />} variant="outlined" sx={{ mr: 1 }} onClick={handleDownloadResume}>
          Download Resume
        </Button>
        <Button startIcon={<LinkedIn />} variant="outlined" onClick={handlePreviewLinkedIn}>
          Preview LinkedIn
        </Button>
      </Box>

      {candidate.audio_file_url && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Button onClick={handlePlayPause}>
              {isPlaying ? <Pause /> : <PlayArrow />}
            </Button>
            <Slider
              sx={{ mx: 2, flexGrow: 1 }}
              value={currentTime}
              max={duration}
              onChange={handleSliderChange}
            />
            <Typography sx={{ minWidth: '60px', textAlign: 'right' }}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Speed sx={{ mr: 1 }} />
              <Select
                value={playbackRate}
                onChange={handlePlaybackRateChange}
                sx={{ mr: 2, height: '36px' }}
                size="small"
              >
                <MenuItem value={0.5}>0.5x</MenuItem>
                <MenuItem value={1}>1x</MenuItem>
                <MenuItem value={1.5}>1.5x</MenuItem>
                <MenuItem value={2}>2x</MenuItem>
              </Select>
            </Box>
            <Button variant="outlined" onClick={handleDownloadAudio} size="small">
              Download Audio
            </Button>
          </Box>
          <audio
            ref={audioRef}
            src={candidate.audio_file_url}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            onError={handleAudioError}
          />
        </Paper>
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>View Transcript</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TranscriptDisplay transcript={candidate.audio_transcript || []} />
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Interview Responses</Typography>
            {candidate.interview_responses && Object.keys(candidate.interview_responses).length > 0 ? (
              Object.entries(candidate.interview_responses).map(([question, responseData], index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {question}
                  </Typography>
                  {renderInterviewResponse(responseData)}
                  {index < Object.keys(candidate.interview_responses).length - 1 && (
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  )}
                </Box>
              ))
            ) : (
              <Typography>No interview responses available</Typography>
            )}
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Candidate Strengths</Typography>
                <ul>
                  {candidate.strengths?.map((strength, index) => (
                    <li key={index}>{strength}</li>
                  ))}
                </ul>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Candidate Weaknesses</Typography>
                <ul>
                  {candidate.weaknesses?.map((weakness, index) => (
                    <li key={index}>{weakness}</li>
                  ))}
                </ul>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Candidate Sentiment</Typography>
                <Typography>{candidate.sentiment}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Cultural Fit</Typography>
                <Typography>{candidate.cultural_fit_reasoning}</Typography>
              </Paper>
            </Grid>
          </Grid>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Delivery of Speech</Typography>
            <Typography>{candidate.speech_skills}</Typography>
          </Paper>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Candidate Questions</Typography>
            {candidate.candidate_questions && candidate.candidate_questions.length > 0 ? (
              <List>
                {candidate.candidate_questions.map((question, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={`${index + 1}. ${question}`} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>Candidate asked no questions</Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Notes</Typography>
              <textarea
                value={notes}
                onChange={handleNotesChange}
                rows={5}
                style={{
                  width: '27rem',
                  height: '8rem',
                  border: '1px solid #D9D9D9',
                  padding: '0.5rem',
                  borderRadius: '0.375rem',
                  resize: 'none'
                }}
                onBlur={saveNotes}
              />
            </CardContent>
          </Card>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Top Skills</Typography>
            <List>
              {candidate.top_skills && candidate.top_skills.length > 0 ? (
                candidate.top_skills.map((skillObj, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${index + 1}. ${skillObj.skill}`}
                      secondary={skillObj.reasoning}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No skills available" />
                </ListItem>
              )}
            </List>
          </Paper>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Candidate Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography><strong>Cohort:</strong> {
                  candidate.ranking === 0 ? 'C' :
                  candidate.ranking === 1 ? 'B' :
                  candidate.ranking === 2 ? 'A' : 'N/A'
                }</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={playbackError ? 'error' : 'info'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CandidateDetailsComponent;
