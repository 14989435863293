import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const StatusBubble = ({ isSpeaking }) => {
  const color = isSpeaking ? '#FFA500' : '#4CAF50';
  const text = isSpeaking ? 'Pluto is speaking' : 'Pluto is listening';

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: color,
        borderRadius: '20px',
        padding: '8px 16px',
        animation: `${pulse} 2s infinite`,
      }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: 'white',
          marginRight: 1,
        }}
      />
      <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default StatusBubble;