import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = () => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract query parameters from URL
    const params = new URLSearchParams(location.search);
    const organizationId = params.get('organization_id');

    // Store organization_id in localStorage if it exists
    if (organizationId) {
      localStorage.setItem('organization_id', organizationId);
    }

    // Set up axios interceptor for token expiration
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
          navigate('/signin', { state: { from: location } });
        }
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [location, logout, navigate]);

  if (isAuthenticated === null) {
    // Optionally render a loading spinner or similar here
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to sign-in with the original destination encoded as the redirect
    return <Navigate to={`/signin?redirect=${location.pathname}`} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
