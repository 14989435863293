import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

const TipsStep = ({ onNext, onBack }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    border: '2px solid #4a8f99',
                    borderRadius: '8px',
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: 600,
                    margin: '0 auto',
                }}
            >
                <Typography variant="h5" gutterBottom sx={{ color: '#4a8f99' }}>
                    Tips for the Best Experience
                </Typography>
                <List sx={{ display: 'inline-block', textAlign: 'left' }}>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline sx={{ color: '#4a8f99' }} />
                        </ListItemIcon>
                        <ListItemText primary="Set aside 10 minutes in a quiet place to complete this process." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline sx={{ color: '#4a8f99' }} />
                        </ListItemIcon>
                        <ListItemText primary="Use a desktop or laptop computer. Mobile devices are not currently supported." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline sx={{ color: '#4a8f99' }} />
                        </ListItemIcon>
                        <ListItemText primary="Have your resume ready in PDF format." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline sx={{ color: '#4a8f99' }} />
                        </ListItemIcon>
                        <ListItemText primary="You can stop and come back to this page at any time to complete the process." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutline sx={{ color: '#4a8f99' }} />
                        </ListItemIcon>
                        <ListItemText primary="If you have any issues or questions, please contact founders@hirepluto.com" />
                    </ListItem>
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, width: '100%', maxWidth: 600, margin: '0 auto' }}>
                    <Button onClick={onBack}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default TipsStep;