import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    Container,
    CircularProgress,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import ResumeInfoReview from './ResumeInfoReview';
import config from './config';

const ApplyJobPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [resume, setResume] = useState(null);
    const [extractedInfo, setExtractedInfo] = useState(null);
    const [hasApplied, setHasApplied] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { jobId } = useParams();
    const navigate = useNavigate();

    // New state for additional fields
    const [experience, setExperience] = useState('');
    const [workAuthorization, setWorkAuthorization] = useState('');
    const [education, setEducation] = useState('');
    const [preferredLocation, setPreferredLocation] = useState('');

    useEffect(() => {
        const checkExistingApplication = async () => {
            try {
                const response = await fetch(`${config.API_URL}/jobs/${jobId}/application-status`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();

                if (data.exists) {
                    setHasApplied(true);
                    if (data.status === 'In Progress') {
                        const infoResponse = await fetch(`${config.API_URL}/jobs/${jobId}/extracted-info`, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        });
                        const infoData = await infoResponse.json();
                        setExtractedInfo(infoData.extractedInfo);
                    }
                }
            } catch (err) {
                console.error('Error checking application status:', err);
                setError('Failed to check application status');
            } finally {
                setLoading(false);
            }
        };

        checkExistingApplication();
    }, [jobId]);

    const handleResumeChange = (e) => {
        setResume(e.target.files[0]);
    };

    const handleResumeUpload = async (e) => {
        e.preventDefault();
        if (!resume) {
            setError('Please upload your resume');
            return;
        }

        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('resume', resume);

        // Add additional fields to formData
        formData.append('additionalData', JSON.stringify({
            experience,
            workAuthorization,
            education,
            preferredLocation
        }));

        try {
            const response = await fetch(`${config.API_URL}/jobs/${jobId}/apply`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.msg || 'Failed to submit application');
            }

            const data = await response.json();
            setExtractedInfo(data.extractedInfo);
            setHasApplied(true);
            setSuccess('Application submitted successfully');
            navigate(`/application/${jobId}`);
        } catch (err) {
            console.error('Upload error:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleInfoSubmit = async (confirmedInfo) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${config.API_URL}/jobs/${jobId}/apply/confirm`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ confirmedInfo })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.msg || 'Failed to confirm application');
            }

            const data = await response.json();

            setSuccess('Info submitted successfully');
            setTimeout(() => {
                navigate(`/application/${jobId}`, { state: { applicationId: data.applicationId } });
            }, 2000);
        } catch (err) {
            console.error('Confirmation error:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteApplication = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${config.API_URL}jobs/${jobId}/apply`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.msg || 'Failed to delete application');
            }

            setSuccess('Application deleted successfully');
            setHasApplied(false);
            setExtractedInfo(null);
            setOpenDeleteDialog(false);

            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        } catch (err) {
            console.error('Delete error:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
                <Typography variant="h4" sx={{ mb: 4, color: '#4a8f99' }}>
                    Job Application
                </Typography>
                {hasApplied && !extractedInfo && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        You have already applied for this job.
                    </Alert>
                )}
                {!extractedInfo ? (
                    <Box component="form" onSubmit={handleResumeUpload}>
                        <input
                            accept=".pdf"
                            style={{ display: 'none' }}
                            id="resume-file"
                            type="file"
                            onChange={handleResumeChange}
                            disabled={hasApplied}
                        />
                        <label htmlFor="resume-file">
                            <Button
                                variant="contained"
                                component="span"
                                sx={{ mb: 2 }}
                                disabled={hasApplied}
                            >
                                Upload Resume
                            </Button>
                        </label>
                        {resume && (
                            <Typography sx={{ mb: 2 }}>
                                Selected file: {resume.name}
                            </Typography>
                        )}

                        {/* New form fields */}
                        <TextField
                            fullWidth
                            label="Experience"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            margin="normal"
                            disabled={hasApplied}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Work Authorization</InputLabel>
                            <Select
                                value={workAuthorization}
                                onChange={(e) => setWorkAuthorization(e.target.value)}
                                disabled={hasApplied}
                            >
                                <MenuItem value="US Citizen">US Citizen</MenuItem>
                                <MenuItem value="Green Card">Green Card</MenuItem>
                                <MenuItem value="H1B">H1B</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Education</InputLabel>
                            <Select
                                value={education}
                                onChange={(e) => setEducation(e.target.value)}
                                disabled={hasApplied}
                            >
                                <MenuItem value="No Formal Education">No Formal Education</MenuItem>
                                <MenuItem value="Associate's Degree">Associate's Degree</MenuItem>
                                <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
                                <MenuItem value="Master's Degree">Master's Degree</MenuItem>
                                <MenuItem value="PhD">PhD</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Location</InputLabel>
                            <Select
                                value={preferredLocation}
                                onChange={(e) => setPreferredLocation(e.target.value)}
                                disabled={hasApplied}
                            >
                                <MenuItem value="In Person">In Person</MenuItem>
                                <MenuItem value="Hybrid">Hybrid</MenuItem>
                                <MenuItem value="Remote">Remote</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading || !resume || hasApplied}
                            sx={{
                                mt: 2,
                                bgcolor: '#4a8f99',
                                '&:hover': { bgcolor: '#3b7b84' }
                            }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Submit Application'}
                        </Button>
                    </Box>
                ) : (
                    <ResumeInfoReview
                        extractedInfo={extractedInfo}
                        onSubmit={handleInfoSubmit}
                    />
                )}
                {hasApplied && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenDeleteDialog(true)}
                        sx={{ mt: 2 }}
                    >
                        Delete Application
                    </Button>
                )}
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" sx={{ mt: 2 }}>
                        {success}
                    </Alert>
                )}
            </Paper>
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Application?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete your application for this job? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteApplication} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ApplyJobPage;