import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, Button, Grid, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Container, CircularProgress, InputLabel, Tooltip, Checkbox } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AdditionalInfoStep = ({ additionalInfo, onInputChange, onSubmit, applicationForm, onResumeUpload, resumeFileName }) => {
    const themeColor = '#4a8f99';
    const [resume, setResume] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [submitDisabledReason, setSubmitDisabledReason] = useState('');

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            setResume(file);
            onResumeUpload(acceptedFiles);
        },
        accept: { 'application/pdf': ['.pdf'] },
        maxSize: 2 * 1024 * 1024,
        multiple: false
    });

    const checkSubmitDisabled = useCallback(() => {
        // Check if resume is required and not uploaded
        if (applicationForm?.resume?.requirement === 'required' && !resume) {
            setSubmitDisabledReason('Missing valid resume');
            return true;
        }

        // Check if any required fields are empty
        for (const [field, data] of Object.entries(applicationForm || {})) {
            if (data.requirement === 'required' && field !== 'resume' && !additionalInfo[field]) {
                setSubmitDisabledReason(`Missing required field: ${data.name || field}`);
                return true;
            }
        }

        // Check custom questions
        if (applicationForm?.custom_questions) {
            for (let i = 0; i < applicationForm.custom_questions.length; i++) {
                const question = applicationForm.custom_questions[i];
                if (question.requirement === 'required' && !additionalInfo[`custom_${i}`]) {
                    setSubmitDisabledReason(`Missing answer for required question: ${question.question}`);
                    return true;
                }
            }
        }

        setSubmitDisabledReason('');
        return false;
    }, [applicationForm, additionalInfo, resume]);

    useEffect(() => {
        setSubmitDisabled(checkSubmitDisabled());
    }, [checkSubmitDisabled]);

    useEffect(() => {
        // Initialize custom questions with default values
        if (applicationForm?.custom_questions) {
            const defaultValues = {};
            applicationForm.custom_questions.forEach((question, index) => {
                const fieldName = `custom_${index}`;
                if (additionalInfo[fieldName] === undefined) {
                    switch (question.question_type) {
                        case 'shortAnswer':
                        case 'longAnswer':
                            defaultValues[fieldName] = '';
                            break;
                        case 'singleSelect':
                            defaultValues[fieldName] = '';
                            break;
                        case 'multiSelect':
                            defaultValues[fieldName] = [];
                            break;
                        case 'number':
                            defaultValues[fieldName] = '';
                            break;
                        default:
                            defaultValues[fieldName] = '';
                    }
                }
            });
            if (Object.keys(defaultValues).length > 0) {
                onInputChange({ target: { name: 'customQuestions', value: defaultValues } });
            }
        }
    }, [applicationForm, additionalInfo, onInputChange]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Format custom question responses
        const formattedAdditionalInfo = { ...additionalInfo };
        applicationForm.custom_questions.forEach((question, index) => {
            const fieldName = `custom_${index}`;
            if (formattedAdditionalInfo[fieldName] !== undefined) {
                if (Array.isArray(formattedAdditionalInfo[fieldName])) {
                    formattedAdditionalInfo[fieldName] = formattedAdditionalInfo[fieldName].join(', ');
                } else {
                    formattedAdditionalInfo[fieldName] = formattedAdditionalInfo[fieldName].toString();
                }
            } else {
                formattedAdditionalInfo[fieldName] = '';
            }
        });

        onSubmit(formattedAdditionalInfo, resume);
    };

    if (!applicationForm) {
        return (
            <Container maxWidth="md" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}>
                <CircularProgress />
            </Container>
        );
    }

    const renderField = (field, data) => {
        if (field === 'custom_questions') return null;
        if (data.requirement === 'hidden') return null;

        const fieldName = data.name || field;
        const isOptional = data.requirement === 'optional';
        const isRequired = data.requirement === 'required';
        const labelText = `${fieldName}${isRequired ? ' *' : ''}${isOptional ? ' (optional)' : ''}`;

        if (field === 'resume' && (isOptional || isRequired)) {
            return (
                <Box>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>{labelText}</Typography>
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #4a8f99',
                            borderRadius: '16px',
                            p: 4,
                            textAlign: 'center',
                            cursor: 'pointer',
                            mb: 3,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: 'rgba(74, 143, 153, 0.1)',
                            },
                        }}
                    >
                        <input {...getInputProps()} />
                        <CloudUploadIcon sx={{ fontSize: 48, color: themeColor, mb: 2 }} />
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            {isDragActive ? 'Drop your resume here' : 'Click or drop your resume here'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Only PDFs are accepted. Max 2MB file size.
                        </Typography>
                    </Box>
                    {resumeFileName && (
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            File selected: {resumeFileName}
                        </Typography>
                    )}
                </Box>
            );
        }

        return (
            <Box>
                <InputLabel htmlFor={field} sx={{ mb: 1 }}>{labelText}</InputLabel>
                <TextField
                    fullWidth
                    id={field}
                    name={field}
                    value={additionalInfo[field] || ''}
                    onChange={onInputChange}
                    required={isRequired}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
            </Box>
        );
    };

    const renderCustomQuestion = (question, index) => {
        const fieldName = `custom_${index}`;
        const isOptional = question.requirement === 'optional';
        const isRequired = question.requirement === 'required';
        const labelText = `${question.question}${isRequired ? ' *' : ''}${isOptional ? ' (optional)' : ''}`;

        switch (question.question_type) {
            case 'shortAnswer':
            case 'longAnswer':
                return (
                    <Box>
                        <InputLabel htmlFor={fieldName} sx={{ mb: 1 }}>{labelText}</InputLabel>
                        <TextField
                            fullWidth
                            id={fieldName}
                            name={fieldName}
                            value={additionalInfo[fieldName] || ''}
                            onChange={onInputChange}
                            required={question.requirement === 'required'}
                            variant="outlined"
                            multiline={question.question_type === 'longAnswer'}
                            rows={question.question_type === 'longAnswer' ? 4 : 1}
                            sx={{ mb: 2 }}
                        />
                    </Box>
                );
            case 'singleSelect':
                return (
                    <FormControl component="fieldset" fullWidth sx={{ mb: 2 }} required={question.requirement === 'required'}>
                        <FormLabel component="legend">{labelText}</FormLabel>
                        <RadioGroup
                            name={fieldName}
                            value={additionalInfo[fieldName] || ''}
                            onChange={onInputChange}
                        >
                            {question.choices && question.choices.map((choice, choiceIndex) => (
                                <FormControlLabel
                                    key={choiceIndex}
                                    value={choice}
                                    control={<Radio />}
                                    label={choice}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case 'multiSelect':
                return (
                    <FormControl component="fieldset" fullWidth sx={{ mb: 2 }} required={question.requirement === 'required'}>
                        <FormLabel component="legend">{labelText}</FormLabel>
                        <Grid container spacing={1}>
                            {question.choices && question.choices.map((choice, choiceIndex) => (
                                <Grid item xs={6} sm={4} key={choiceIndex}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={additionalInfo[fieldName]?.includes(choice) || false}
                                                onChange={(e) => {
                                                    const updatedChoices = e.target.checked
                                                        ? [...(additionalInfo[fieldName] || []), choice]
                                                        : (additionalInfo[fieldName] || []).filter(item => item !== choice);
                                                    onInputChange({
                                                        target: { name: fieldName, value: updatedChoices }
                                                    });
                                                }}
                                            />
                                        }
                                        label={choice}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </FormControl>
                );

            case 'number':
                return (
                    <Box>
                        <InputLabel htmlFor={fieldName} sx={{ mb: 1 }}>{labelText}</InputLabel>
                        <TextField
                            fullWidth
                            id={fieldName}
                            name={fieldName}
                            type="number"
                            value={additionalInfo[fieldName] || ''}
                            onChange={onInputChange}
                            required={question.requirement === 'required'}
                            variant="outlined"
                            inputProps={{
                                min: question.min_value,
                                max: question.max_value,
                                step: question.step || 1
                            }}
                            sx={{ mb: 2 }}
                        />
                    </Box>
                );

            default:
                return null;
        }
    };

    return (
        <Container maxWidth="md" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 4,
            height: '100%',
            overflowY: 'auto'
        }}>
            <form onSubmit={handleSubmit} style={{
                width: '100%',
                border: `2px solid ${themeColor}`,
                borderRadius: '8px',
                padding: '24px'
            }}>
                <Typography variant="h5" align="center" sx={{ mb: 4, color: themeColor }}>
                    Additional Information
                </Typography>
                <Grid container spacing={2}>
                    {/* Render resume upload first */}
                    {applicationForm.resume && applicationForm.resume.requirement !== 'hidden' && (
                        <Grid item xs={12}>
                            {renderField('resume', applicationForm.resume)}
                        </Grid>
                    )}
                    {/* Render other fields */}
                    {Object.entries(applicationForm).map(([field, data]) => (
                        field !== 'custom_questions' && field !== 'resume' && data.requirement !== 'hidden' && (
                            <Grid item xs={12} key={field}>
                                {renderField(field, data)}
                            </Grid>
                        )
                    ))}
                    {/* Render custom questions */}
                    {applicationForm.custom_questions && applicationForm.custom_questions.map((question, index) => (
                        question.requirement !== 'hidden' && (
                            <Grid item xs={12} key={`custom_${index}`}>
                                {renderCustomQuestion(question, index)}
                            </Grid>
                        )
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
                    <Tooltip title={submitDisabledReason} placement="top">
                        <span>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={submitDisabled}
                                sx={{
                                    bgcolor: themeColor,
                                    '&:hover': { bgcolor: '#3b7b84' }
                                }}
                            >
                                Submit
                            </Button>
                        </span>
                    </Tooltip>
                    {submitDisabledReason && (
                        <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                            {submitDisabledReason}
                        </Typography>
                    )}
                </Box>
            </form>
        </Container>
    );
};

export default AdditionalInfoStep;
