import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, CircularProgress, Container, Paper } from '@mui/material';
import { RetellWebClient } from "retell-client-js-sdk";
import config from './config';

const JobApplicationPage = () => {
  const { jobId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isCallActive, setIsCallActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [callStatus, setCallStatus] = useState('');
  const [transcript, setTranscript] = useState([]);
  const retellWebClientRef = useRef(null);
  const transcriptRef = useRef(null);
  const throttleTimerRef = useRef(null);
  const [, setApplicationId] = useState(null);
  const [callEnded, setCallEnded] = useState(false);

  useEffect(() => {
    if (location.state && location.state.applicationId) {
      setApplicationId(location.state.applicationId);
    }
  }, [location]);

  const updateTranscript = useCallback((newTranscript) => {
    console.log('Updating transcript:', newTranscript);
    if (throttleTimerRef.current) {
      clearTimeout(throttleTimerRef.current);
    }
    throttleTimerRef.current = setTimeout(() => {
      setTranscript(newTranscript);
    }, 1000); // Throttle to once per second
  }, []);

  const setupEventListeners = useCallback(() => {
    if (!retellWebClientRef.current) return;

    retellWebClientRef.current.on("call_started", () => {
      console.log("Call started");
      setCallStatus("Call started");
      setIsCallActive(true);
      setLoading(false);
    });

    retellWebClientRef.current.on("call_ended", () => {
      console.log("Call ended");
      setCallStatus("Call ended");
      setIsCallActive(false);
      setCallEnded(true);
    });

    retellWebClientRef.current.on("agent_start_talking", () => {
      console.log("Agent started talking");
      setCallStatus("Agent is speaking");
    });

    retellWebClientRef.current.on("agent_stop_talking", () => {
      console.log("Agent stopped talking");
      setCallStatus("Agent finished speaking");
    });

    retellWebClientRef.current.on("update", (update) => {
      console.log("Update received:", update);
      if (update.event_type === 'update' && update.transcript) {
        updateTranscript(update.transcript);
      }
    });

    retellWebClientRef.current.on("error", (error) => {
      console.error("An error occurred:", error);
      setError(`Call error: ${error.message}`);
      handleEndCall();
    });
  }, [updateTranscript]);

  useEffect(() => {
    console.log('Transcript updated:', transcript);
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript]);


  const handleStartCall = async () => {
    try {
      setLoading(true);
      setError(null);

      // Generate access token
      const response = await fetch(`${config.API_URL}/jobs/create-call`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ jobId })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || 'Failed to create call');
      }

      // Initialize RetellWebClient
      retellWebClientRef.current = new RetellWebClient();
      setupEventListeners();

      // Start the call
      await retellWebClientRef.current.startCall({
        accessToken: data.accessToken,
        sampleRate: 24000,
        captureDeviceId: "default",
        emitRawAudioSamples: false,
      });

    } catch (err) {
      console.error('Start call error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEndCall = () => {
    if (retellWebClientRef.current) {
      retellWebClientRef.current.stopCall();
    }
    setIsCallActive(false);
    setCallStatus("Call ended by user");
    setCallEnded(true);
  };

  const handleCompleteApplication = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${config.API_URL}/jobs/${jobId}/complete-application`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ jobId })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to complete application');
      }

      const data = await response.json();
      console.log('Application completed:', data);
      navigate('/'); // Redirect to home page or job listings
    } catch (err) {
      console.error('Complete application error:', err);
      setError(`Failed to complete application: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (retellWebClientRef.current) {
        retellWebClientRef.current.stopCall();
      }
      if (throttleTimerRef.current) {
        clearTimeout(throttleTimerRef.current);
      }
    };
  }, []);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
        <Typography variant="h4" sx={{ mb: 4, color: '#4a8f99' }}>
          Job Application - Interview Call
        </Typography>
        <Box>
          {error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>Call Status: {callStatus}</Typography>
              {!isCallActive && !callEnded && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStartCall}
                  disabled={loading}
                  sx={{ mt: 2, mb: 3 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Start Call'}
                </Button>
              )}
              {isCallActive && (
                <>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Your call is currently in progress. Please speak clearly and follow the agent's instructions.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleEndCall}
                    sx={{ mt: 2, mb: 3 }}
                  >
                    End Call
                  </Button>
                </>
              )}
              {callEnded && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCompleteApplication}
                  disabled={loading}
                  sx={{ mt: 2, mb: 3 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Complete Application'}
                </Button>
              )}
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>Transcript:</Typography>
              <Paper
                elevation={1}
                sx={{ p: 2, maxHeight: '300px', overflowY: 'auto' }}
                ref={transcriptRef}
              >
                {transcript.map((entry, index) => (
                  <Typography key={index} variant="body2" sx={{ mb: 1 }}>
                    <strong>{entry.role === 'agent' ? 'Agent' : 'You'}: </strong>
                    {entry.content}
                  </Typography>
                ))}
              </Paper>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default JobApplicationPage;