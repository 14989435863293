/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Tabs,
  Tab,
  Container,
  Snackbar,
  ThemeProvider,
  CssBaseline,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import config from './config';
import theme from './theme';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const SignInPage = () => {
  const [tab, setTab] = useState(0);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to access query parameters
  const { login, isAuthenticated } = useAuth();

  const [loginStep, setLoginStep] = useState(0);
  const [loginPhoneNumber, setLoginPhoneNumber] = useState('');
  const [loginVerificationCode, setLoginVerificationCode] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const organizationId = queryParams.get('organization_id');
    const invite_link_id = queryParams.get('invite_link_id');
    if (organizationId) {
      localStorage.setItem('organization_id', organizationId);
      localStorage.setItem('invite_link_id', invite_link_id);
    }
  }, [location.search]); // Depend on location.search to react to query parameter changes

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setStep(0);
  };

  const handleNumberInput = (setter) => (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setter(value);
  };

  const handlePhoneChange = (value, country, event, formattedValue) => {
    // Format the phone number to include the "+" and remove any non-digit characters
    const formattedPhoneNumber = "+" + value.replace(/\D/g, '');
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleLoginPhoneChange = (value, country, event, formattedValue) => {
    // Format the phone number to include the "+" and remove any non-digit characters
    const formattedPhoneNumber = "+" + value.replace(/\D/g, '');
    setLoginPhoneNumber(formattedPhoneNumber);
  };

  const validateForm = () => {
    if (tab === 1) {
      if (firstName.trim() === '' || lastName.trim() === '') {
        setMessage('First and Last Name are required');
        return false;
      }
      if (!phoneNumber) {
        setMessage('Phone number is required');
        return false;
      }
    }
    if (email.trim() === '') {
      setMessage('Email is required');
      return false;
    }
    return true;
  };

  const handleSendVerificationCode = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    try {
      const response = await axios.post(`${config.API_URL}/users/send-sms-code`, {
        phone_number: phoneNumber
      });

      setStep(1);
      setMessage(response.data.message || 'Verification code sent. Please check your phone.');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.detail || 'Failed to send verification code');
      } else if (error.request) {
        setMessage('No response received from server. Please try again.');
      } else {
        setMessage('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendLoginVerificationCode = async () => {
    if (!loginPhoneNumber) {
      setMessage('Phone number is required');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${config.API_URL}/users/send-sms-code`, {
        phone_number: loginPhoneNumber
      });

      setLoginStep(1);
      setMessage(response.data.message || 'Verification code sent. Please check your phone.');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.detail || 'Failed to send verification code');
      } else if (error.request) {
        setMessage('No response received from server. Please try again.');
      } else {
        setMessage('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    const organizationId = localStorage.getItem('organization_id');
    const invite_link_id = localStorage.getItem('invite_link_id');
    const url = tab === 0 ? `${config.API_URL}/users/login` : `${config.API_URL}/users/auth_sms`;
    const body = tab === 0
      ? { email }
      : {
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        code: verificationCode,
        organization_id: organizationId ? parseInt(organizationId, 10) : undefined,
        invite_link_id: invite_link_id ? parseInt(invite_link_id, 10) : undefined
      };

    try {
      const response = await axios.post(url, body);
      login(response.data.access_token, response.data.role);
      setMessage(tab === 0 ? 'Login successful!' : 'Registration successful!');

      const redirectTo = new URLSearchParams(location.search).get('redirect') || '/';
      navigate(redirectTo);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.status === 422) {
          errorMessage = 'Invalid input. Please check your information and try again.';
          console.error('Validation errors:', error.response.data);
        } else if (error.response.status === 400) {
          errorMessage = error.response.data.detail || 'Bad request. Please check your information.';
        } else {
          errorMessage = error.response.data.detail || 'An error occurred';
        }
      } else if (error.request) {
        errorMessage = 'No response received from server. Please try again.';
      }
      setMessage(errorMessage);
      if (tab === 1) setStep(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (!loginPhoneNumber || !loginVerificationCode.trim()) {
      setMessage('All fields are required');
      return;
    }
    setIsLoading(true);
    const organizationId = localStorage.getItem('organization_id');
    const invite_link_id = localStorage.getItem('invite_link_id');
    try {
      const response = await axios.post(`${config.API_URL}/users/login_sms`, {
        phone_number: loginPhoneNumber,
        code: loginVerificationCode,
        organization_id: organizationId,
        invite_link_id: invite_link_id
      });
      login(response.data.access_token, response.data.role);
      setMessage('Login successful!');

      const redirectTo = new URLSearchParams(location.search).get('redirect') || '/';
      navigate(redirectTo);
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.detail || 'An error occurred');
      } else if (error.request) {
        setMessage('No response received from server. Please try again.');
      } else {
        setMessage('An error occurred. Please try again.');
      }
      setLoginStep(0);
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container component="main" maxWidth="xs">
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Box
              component="img"
              src="/logo.png"
              alt="Retell AI Logo"
              sx={{
                width: 150,
                height: 'auto',
                mb: 3,
              }}
            />
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              {tab === 0 ? 'Sign In' : 'Register'}
            </Typography>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              sx={{ mb: 3, width: '100%' }}
            >
              <Tab label="Sign In" />
              <Tab label="Register" />
            </Tabs>
            {tab === 1 && (
              <Box sx={{ width: '100%', mb: 3, textAlign: 'center' }}>
                <Typography variant="body1">
                  We are currently in closed beta. Email <a href="mailto:founders@hirepluto.com">founders@hirepluto.com</a> if you'd like to join.
                </Typography>
              </Box>
            )}
            <Box component="form" onSubmit={tab === 0 ? handleLoginSubmit : handleSubmit} sx={{ mt: 1, width: '100%' }}>
              {tab === 0 ? (
                loginStep === 0 ? (
                  <>
                    <PhoneInput
                      country={'us'}
                      value={loginPhoneNumber}
                      onChange={handleLoginPhoneChange}
                      inputProps={{
                        required: true,
                        autoFocus: true,
                      }}
                      containerStyle={{ marginTop: '16px', marginBottom: '8px' }}
                      inputStyle={{ width: '100%' }}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSendLoginVerificationCode}
                      disabled={isLoading}
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.5,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                    >
                      {isLoading ? <CircularProgress size={24} /> : 'Send Verification Code'}
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Verification Code"
                      name="loginVerificationCode"
                      value={loginVerificationCode}
                      onChange={(e) => setLoginVerificationCode(e.target.value)}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isLoading}
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.5,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                    >
                      {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
                    </Button>
                  </>
                )
              ) : (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <PhoneInput
                    country={'us'}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    inputProps={{
                      required: true,
                    }}
                    containerStyle={{ marginTop: '16px', marginBottom: '8px' }}
                    inputStyle={{ width: '100%' }}
                  />
                  {step === 1 && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Verification Code"
                      name="verificationCode"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    onClick={step === 0 ? handleSendVerificationCode : handleSubmit}
                    sx={{
                      mt: 3,
                      mb: 2,
                      py: 1.5,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : step === 0 ? 'Send Verification Code' : 'Register'}
                  </Button>
                </>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message || ''}
      />
    </ThemeProvider>
  );
};

export default SignInPage;
