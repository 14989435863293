import React from 'react';
import { Typography, Box, Button, Paper, Stepper, Step, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomStepIcon = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));


const WelcomeStep = ({ onNext, onToggleJobDetails, showJobDetails, jobDetails }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Paper
      elevation={0}
      sx={{
        p: 4,
        mb: 4,
        borderRadius: 4,
        border: (theme) => `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {jobDetails && jobDetails.id === 26 ? (
        <Typography variant="h3" sx={{ color: '#4a8f99', textAlign: 'center', mb: 2}}>
          Hi! I'm Pluto, your personal headhunter
        </Typography>
      ) : (
        <Typography variant="h3" sx={{ color: '#4a8f99', textAlign: 'center', mb: 2}}>
          Hey there!
        </Typography>
      )}
      {jobDetails && jobDetails.id === 26 ? (
        <Typography variant="h4" sx={{ mb: 4, mt: 4, color: '#4a8f99' }}>
          How this works
        </Typography>
      ) : (
        <Typography variant="h4" sx={{ mb: 4, color: '#4a8f99' }}>
          Welcome to Pluto, the conversational cover letter.
        </Typography>
      )}
      <Typography
        variant="h6"
        sx={{
          mb: 4,
          flexGrow: 1,
          fontSize: '1.25rem', // Increased font size
          lineHeight: 1.6,    // Adjusted line height for better readability
          '& p': { mb: 2 }    // Add margin to bottom of paragraphs
        }}
      >
        {jobDetails && jobDetails.id === 26 ? (
          <>
            <Stepper activeStep={3} alternativeLabel sx={{ width: '100%', mt: 2 }}>
              <Step>
                <CustomStepLabel
                  StepIconComponent={() => (
                    <CustomStepIcon>1</CustomStepIcon>
                  )}
                >
                  Create your profile
                  <StepContent>
                    <Typography variant="body1">
                      Share some basic details to set up your profile — it only takes 30 seconds.
                    </Typography>
                  </StepContent>
                </CustomStepLabel>
              </Step>
              <Step>
                <CustomStepLabel
                  StepIconComponent={() => (
                    <CustomStepIcon>2</CustomStepIcon>
                  )}
                >
                  Chat with Pluto
                  <StepContent>
                    <Typography variant="body1">
                      Chat with Pluto for 10 minutes so we can understand you better and build your profile.
                    </Typography>
                  </StepContent>
                </CustomStepLabel>
              </Step>
              <Step>
                <CustomStepLabel
                  StepIconComponent={() => (
                    <CustomStepIcon>3</CustomStepIcon>
                  )}
                >
                  Get interviews
                  <StepContent>
                    <Typography variant="body1">
                      We’ll refer your profile to 100+ companies and let you know if recruiters want to interview you.
                    </Typography>
                  </StepContent>
                </CustomStepLabel>
              </Step>
            </Stepper>
            <p><strong>Referrals:</strong></p>
            <p>Refer a friend, and Pluto will give your profile a boost. Whether they’re job hunting or just seeing what’s out there, share this link to help them out!</p>
            <p><strong>Need help?</strong></p>
            <p>If something isn't working, just email us at <a href="mailto:founders@hirepluto.com">founders@hirepluto.com</a>.</p>
            <p><strong>Ready?</strong></p>
            <p>When you're ready, click ‘Next’ to continue.</p>
          </>
        ) : (
          <>
            <p>You're about to start a live, AI-powered screening, which will take approximately 10 minutes.</p>
            <p>You can come back to this page at any time, you do not have to complete this right now.</p>
            <p>If you experience any issues, please contact us at founders@hirepluto.com</p>
            <p>Please click 'Next' when you're ready to proceed.</p>
          </>
        )}
      </Typography>
    </Paper>

    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 2 }}>
        <Button
          variant="contained"
          onClick={onNext}
          sx={{
            mr: 2,
            bgcolor: '#4a8f99',
            '&:hover': { bgcolor: '#3b7b84' }
          }}
        >
          Next
        </Button>
        <Button
          variant="outlined"
          onClick={onToggleJobDetails}
          sx={{
            color: '#4a8f99',
            borderColor: '#4a8f99',
            '&:hover': { bgcolor: '#e0f2f1' }
          }}
        >
          {showJobDetails ? 'Hide Job Details' : 'Show Job Details'}
        </Button>
      </Box>
      <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
        By continuing with the application you agree to our{' '}
        <a href="https://hirepluto.com/privacy-policy" target="_blank" rel="noopener noreferrer">
          privacy policy
        </a>{' '}
        and{' '}
        <a href="https://hirepluto.com/terms-of-service" target="_blank" rel="noopener noreferrer">
          terms of service
        </a>
      </Typography>
    </Box>
  </Box>
);

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

const StepContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
}));

export default WelcomeStep;