import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  CircularProgress,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Snackbar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import config from './config';

const OrganizationsPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add'); // 'add' or 'edit'
  const [newOrgName, setNewOrgName] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteLink, setInviteLink] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await fetch(`${config.API_URL}/organizations/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch organizations');
      }
      const data = await response.json();
      setOrganizations(data);
      if (data.length > 0) {
        setSelectedOrg(data[0]);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching organizations:', err);
      setError('Failed to load organizations');
      setLoading(false);
    }
  };

  const handleCreateOrganization = async () => {
    try {
      const response = await fetch(`${config.API_URL}/organizations/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: newOrgName })
      });

      if (response.ok) {
        const newOrganization = await response.json();
        setOrganizations([...organizations, newOrganization]);
        setSelectedOrg(newOrganization);
        setNewOrgName('');
        setOpenDialog(false);
        showSnackbar('Organization created successfully');
      } else {
        const errorData = await response.json();
        console.error('Failed to create organization:', errorData.detail || errorData);
        showSnackbar('Failed to create organization');
      }
    } catch (error) {
      console.error('Error creating organization:', error);
      showSnackbar('Error creating organization');
    }
  };

  const handleUpdateOrganization = async () => {
    try {
      const response = await fetch(`${config.API_URL}/organizations/${selectedOrg.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: newOrgName })
      });

      if (response.ok) {
        const updatedOrg = await response.json();
        setOrganizations(organizations.map(org => org.id === updatedOrg.id ? updatedOrg : org));
        setSelectedOrg(updatedOrg);
        setNewOrgName('');
        setOpenDialog(false);
        showSnackbar('Organization updated successfully');
      } else {
        const errorData = await response.json();
        console.error('Failed to update organization:', errorData.detail || errorData);
        showSnackbar('Failed to update organization');
      }
    } catch (error) {
      console.error('Error updating organization:', error);
      showSnackbar('Error updating organization');
    }
  };

  const handleDeleteOrganization = async () => {
    if (deleteConfirmation.toLowerCase() === 'yes') {
      try {
        const response = await fetch(`${config.API_URL}/organizations/${selectedOrg.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          setOrganizations(organizations.filter(org => org.id !== selectedOrg.id));
          setSelectedOrg(organizations.length > 1 ? organizations[0] : null);
          showSnackbar('Organization deleted successfully');
        } else {
          const errorData = await response.json();
          console.error('Failed to delete organization:', errorData.detail || errorData);
          showSnackbar('Failed to delete organization');
        }
      } catch (error) {
        console.error('Error deleting organization:', error);
        showSnackbar('Error deleting organization');
      }
    }
    setDeleteDialogOpen(false);
    setDeleteConfirmation('');
  };

  const handleInviteToOrganization = async () => {
    try {
      const response = await fetch(`${config.API_URL}/organizations/invite/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ email: inviteEmail, organization_id: selectedOrg.id })
      });

      if (response.ok) {
        const data = await response.json();
        setInviteLink(data.invite_link);
        showSnackbar('Invitation link generated successfully');
      } else {
        const errorData = await response.json();
        console.error('Failed to send invitation:', errorData.detail || errorData);
        showSnackbar('Failed to send invitation');
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
      showSnackbar('Error sending invitation');
    }
  };

  const handleCopyInvitation = () => {
    if (inviteLink) {
      navigator.clipboard.writeText(inviteLink)
        .then(() => {
          showSnackbar('Invitation link copied to clipboard');
          setInviteDialogOpen(false);
        })
        .catch((err) => {
          console.error('Failed to copy invitation link:', err);
          showSnackbar('Failed to copy invitation link');
        });
    } else {
      handleInviteToOrganization();
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', gap: 3 }}>
      {/* Sidebar */}
      <Paper 
        elevation={1}
        sx={{ 
          width: 250,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setDialogMode('add');
              setNewOrgName('');
              setOpenDialog(true);
            }}
            sx={{
              py: 1,
              bgcolor: theme.palette.primary.main,
              '&:hover': { bgcolor: theme.palette.primary.dark },
              borderRadius: theme.shape.borderRadius,
            }}
          >
            Add Organization
          </Button>
        </Box>
        <List sx={{ flexGrow: 1, overflowY: 'auto', py: 1, px: 2 }}>
          {organizations.map((org) => (
            <ListItem 
              button 
              key={org.id} 
              onClick={() => setSelectedOrg(org)}
              selected={selectedOrg && selectedOrg.id === org.id}
              sx={{
                borderRadius: '4px',
                my: 0.5,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(74, 143, 153, 0.15)',
                  '&:hover': {
                    backgroundColor: 'rgba(74, 143, 153, 0.25)',
                  },
                },
              }}
            >
              <ListItemText primary={org.name} />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Main content */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
        {selectedOrg ? (
          <Paper 
            elevation={1} 
            sx={{ 
              p: 3, 
              borderRadius: 2, 
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
              <Tooltip title="Invite to Organization">
                <IconButton 
                  onClick={() => setInviteDialogOpen(true)}
                  sx={{ mr: 1 }}
                >
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Organization">
                <IconButton 
                  onClick={() => {
                    setDialogMode('edit');
                    setNewOrgName(selectedOrg.name);
                    setOpenDialog(true);
                  }}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Organization">
                <IconButton 
                  onClick={() => setDeleteDialogOpen(true)}
                  sx={{ 
                    '&:hover': { 
                      color: 'error.main',
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 3, color: theme.palette.primary.main }}>
              {selectedOrg.name}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Credits: {selectedOrg.credits}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Members: {selectedOrg.users.length}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Created: {new Date(selectedOrg.created_date).toLocaleDateString()}
            </Typography>
            <Typography variant="h5" sx={{ mt: 4, mb: 2, color: theme.palette.primary.main }}>
              Members
            </Typography>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              <List>
                {selectedOrg.users.map((user, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={user.name || user.email || `User ${index + 1}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        ) : (
          <Paper 
            elevation={1} 
            sx={{ 
              p: 3, 
              borderRadius: 2, 
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">Select an organization to view details</Typography>
          </Paper>
        )}
      </Box>

      {/* Add/Edit Organization Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{dialogMode === 'add' ? 'Add New Organization' : 'Edit Organization'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Organization Name"
            type="text"
            fullWidth
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={dialogMode === 'add' ? handleCreateOrganization : handleUpdateOrganization}>
            {dialogMode === 'add' ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this organization? This action cannot be undone. Please type "yes" to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Type 'yes' to confirm"
            type="text"
            fullWidth
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteOrganization} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Invite to Organization Dialog */}
      <Dialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)}>
        <DialogTitle>Invite to Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the email address of the person you want to invite to this organization.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          {inviteLink && (
            <Box mt={2}>
              <Typography variant="body1">Invite Link:</Typography>
              <TextField
                fullWidth
                value={inviteLink}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleCopyInvitation}
            startIcon={<ContentCopyIcon />}
          >
            {inviteLink ? 'Copy Invitation' : 'Generate & Copy'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default OrganizationsPage;