import React from 'react';
import { Typography, Box, Paper } from '@mui/material';

const PaymentsPage = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Payments
        </Typography>
        <Typography variant="h5" color="primary">
          For now, it's free!
        </Typography>
      </Paper>
    </Box>
  );
};

export default PaymentsPage;